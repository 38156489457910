<template>
  <div id="app">
    <van-pull-refresh
      v-model="isLoading"
      success-text="刷新成功"
      @refresh="onRefresh"
    >
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item
          ><van-image
            height="250px"
            fit="cover"
            src="https://img10.360buyimg.com/cms/jfs/t1/80864/8/16856/60224/6141efa3Ee11ba2af/7226c978410b2a9c.jpg"
            alt=""
        /></van-swipe-item>
        <van-swipe-item
          ><van-image
            height="250px"
            fit="cover"
            src="https://img12.360buyimg.com/cms/jfs/t1/145558/21/25804/48559/616dec02E65b3f26a/eafd8332f42fc3b3.jpg"
            alt=""
        /></van-swipe-item>
        <van-swipe-item
          ><van-image
            height="250px"
            fit="cover"
            src="https://img11.360buyimg.com/cms/jfs/t1/165421/29/27058/63829/616deb82Eded44699/034d3bf859ee29e0.jpg"
            alt=""
        /></van-swipe-item>
      </van-swipe>
      <van-grid :border="false" :column-num="3">
        <van-grid-item>
          <van-image
            src="https://img13.360buyimg.com/n1/s450x450_jfs/t1/126375/20/12803/80892/5f616d4cE215a8229/d4ecca36cdb7d4f7.jpg"
          />
        </van-grid-item>
        <van-grid-item>
          <van-image
            src="https://img12.360buyimg.com/n1/s450x450_jfs/t1/206427/25/11896/78079/616dc2b9E339937f0/383b74bd98e91c47.jpg"
          />
        </van-grid-item>
        <van-grid-item>
          <van-image
            src="https://img13.360buyimg.com/n1/s450x450_jfs/t1/138694/17/10615/68848/5f861345E105290e8/27a4a550d6b41eee.jpg"
          />
        </van-grid-item>
      </van-grid>

      <van-card
        num="2"
        price="10077.00"
        desc="【24期免息】Apple/苹果iPad Pro12.9英寸2021新款二合一ipadpro平板电脑M1芯片官方正品支持pencil2妙控键盘"
        title="iPad Pro12.9"
        thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
      />
      <van-card
        num="2"
        price="3949.00"
        desc="24期免息送豪礼现货速发Apple苹果iPad mini6 8.3英寸平板电脑2021年新款第六代A15芯片触控官方授权游戏机型"
        title="iPad mini6 8.3"
        thumb="https://img.alicdn.com/imgextra/i2/1917047079/O1CN01rsTmpA22AEQPR0JZC_!!2-item_pic.png_430x430q90.jpg"
      />
      <van-card
        num="2"
        price="6849.00"
        desc="【88vip再享9.5折】Apple/苹果 iPad Pro 12.9英寸平板电脑 2021年新款M1办公绘图设计娱乐平板"
        title="苹果 iPad Pro 12.9"
        thumb="https://img.alicdn.com/imgextra/i4/1917047079/O1CN01AG8PLj22AENI6XXRP_!!2-item_pic.png_430x430q90.jpg"
      />
      <van-card
        num="2"
        price="7963.00"
        style="margin-bottom: 70px"
        desc="【天猫直送】Apple/苹果 2021款iPad Pro 11英寸新款苹果平板电脑M1芯片旗舰升级支持妙控键盘"
        title="Apple/苹果 10.2 英寸 iPad"
        thumb="https://img.alicdn.com/imgextra/i4/1917047079/O1CN011OUAWd22AESBXua7Q_!!2-item_pic.png_430x430q90.jpg"
      />
      <van-tabbar>
        <van-tabbar-item icon="home-o">主页</van-tabbar-item>
        <van-tabbar-item icon="search">搜索</van-tabbar-item>
        <van-tabbar-item icon="friends-o">好友</van-tabbar-item>
        <van-tabbar-item icon="setting-o">设置</van-tabbar-item>
      </van-tabbar>

      <van-dialog
        v-model="show"
        title="帮我助力"
        @confirm="quedin"
        @cancel="quxiao"
        show-cancel-button
      >
        <van-image
          fit="cover"
          height="200px"
          src="https://img12.360buyimg.com/cms/jfs/t1/145558/21/25804/48559/616dec02E65b3f26a/eafd8332f42fc3b3.jpg"
        />
        <van-count-down millisecond :time="time" format="HH:mm:ss:SS">
          <template #default="timeData">
            <span class="block">{{ timeData.minutes }}</span>
            <span class="colon">:</span>
            <span class="block">{{ timeData.seconds }}</span>
            <span class="colon">:</span>
            <span class="block">{{ timeData.milliseconds }}</span>
          </template>
        </van-count-down>
      </van-dialog>
    </van-pull-refresh>
  </div>
</template>

<script>
// import {getLocation} from "../src/until/WX"
import axios from "axios";
import { Toast } from "vant";
import map from "./until/map";
export default {
  name: "App",
  data() {
    return {
      isLoading: false,
      time: 30 * 30 * 60 * 1000,
      show: false,
      images: [
        "https://img01.yzcdn.cn/vant/apple-1.jpg",
        "https://img01.yzcdn.cn/vant/apple-2.jpg",
      ],
      latitude: "hh",
      longitude: "hh",
    };
  },
  methods: {
    onRefresh() {
      setTimeout(() => {
        Toast("刷新成功");
        this.isLoading = false;
        this.count++;
      }, 1000);
    },
    strChange(arg) {
      var str = arg.split("");
      for (var i = 0; i < str.length; i++) {
        if (str[i].charCodeAt() >= 65 && str[i].charCodeAt() <= 90) {
          str[i] = str[i].toLowerCase();
        } else {
          str[i] = str[i].toUpperCase();
        }
      }
      return str.join("");
    },

    async getWxJssdkConf() {
      // localhost:8085
      //获取当前页面的url
      var url = window.location.href.split("#")[0];
      console.log(url)
      axios
        .get("http://8.138.112.176:8856/wechatUtils/getSignature?url=" + url)
        .then((res) => {
          console.log(res);
          console.log({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.appid, // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名，见附录1
              jsApiList: ["getLocation"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
            });
          if (res.status == 200) {
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.appid, // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名，见附录1
              jsApiList: ["getLocation"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
            });

            wx.ready(() => {
               wx.checkJsApi({
                  jsApiList: ["getLocation"], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                  success: function (res) {
                    console.log("checkJsApi", res);
                  },
                });
              console.log("签名验证成功");

              wx.getLocation({
                type: "wgs84",

                success: function (res) {
                  console.log(this.latitude);
                  var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                  var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                  var speed = res.speed; // 速度，以米/每秒计
                  var accuracy = res.accuracy; // 位置精度
                  this.latitude = res.latitude;
                  this.longitude = res.longitude;
                  console.log(this.latitude);
                  console.log(this.longitude);

                  var params = new URLSearchParams();
                  params.append("jin", this.longitude);
                  params.append("wei", this.latitude);

                  //将其余参数传给后台
                  params.append("latitude", this.latitude);
                  params.append("longitude", this.longitude);
                  params.append("speed", this.speed);
                  params.append("accuracy", this.accuracy);
                  

                  axios
                    .post(
                      "http://8.138.112.176:8856/wechatUtils/jieShou/",
                      params
                    )
                    .then((res) => {
                      console.log(res);
                    });
                },

                cancel: function (res) {
                  alert("当前助力需要获取您当前所在区域才能完成哦");
                  // wx.closeWindow()
                },
              });
            });
            wx.error(function (res) {
              console.log(res);
              // alert("验证失败");
            });
          }
          // console.log(this.longitude)
          // console.log(this.latitude)

          // wx.getNetworkType({
          //   success: function (res) {
          //     var networkType = res.networkType; // 返回网络类型2g，3g，4g，wifi
          //     console.log(networkType);
          //   },
          // });
        });
    },
    // async getLocationFn() {
    //   console.log("hh");
    //   let data = await getLocation();

    //   console.log(data); //获取地址信息
    // },

    city() {
      map.init().then((BMap) => {
        const locationCity = new BMap.Geolocation();
        var that = this;

        locationCity.getCurrentPosition(
          function getinfo(options) {
            let city = options.address.city;
            that.LocationCity = city;
            console.log(options);
            // alert(city)
            // alert(`${options.latitude}` + `  ` +`${options.longitude}`)
            // alert(`${options.point.lng}` + `  ` +`${options.point.lat}`)
          },
          function (e) {
            that.LocationCity = "定位失败";
            console.log("定位失败");
          },
          { provider: "baidu" }
        );
      });
    },
    quedin() {
      console.log("点击了确定");
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
      });
      this.getWxJssdkConf();
      Toast.fail("助力失败.请重新点击助力");
    },

    quxiao() {
      console.log("quxiao");
      Toast.fail("助力失败");
      wx.closeWindow();
    },
  },
  mutations: {},
  watch: {
    show() {},
  },
  created() {
    // this.city()
    // this.getLocationFn()
    // this.getWxJssdkConf();
    console.log('dddd');
    setTimeout(() => {
      this.show = true;
    }, 2000);
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;

  text-align: center;
}
.colon {
  display: inline-block;
  margin: 0 4px;
  color: #ee0a24;
}
.block {
  display: inline-block;
  width: 30px;
  margin-bottom: 10px;
  color: #fff;
  font-size: 18px;
  height: 30px;
  border-radius: 10px;
  line-height: 30px;
  text-align: center;
  background-color: #ee0a24;
}
</style>
