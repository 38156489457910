import Vue from 'vue'
import App from './App.vue'
// import wx from "weixin-js-sdk"
import BaiduMap from 'vue-baidu-map'
import axios from "axios"

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

Vue.config.productionTip = false
// Vue.prototype.$wx = wx;
Vue.use(BaiduMap,{
  ak:'7FZSbaEwGpiGOp0qLP09zf4GQicu0git'//自己去百度API申请，免费的
})

new Vue({
  render: h => h(App),
}).$mount('#app')
